@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

#app > ul {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) translateY(-10px);
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none; }
  #app > ul > li {
    display: inline-block;
    margin: 10px; }

@media (min-width: 1100px) {
  #app #profile, #app > ul > li > h1, #app .info {
    transition: opacity 0.2s; }
  #app:not(.undefined) #profile, #app:not(.undefined) > ul > li > h1, #app:not(.undefined) .info {
    opacity: 0; } }

@media (max-width: 1099px) {
  #app > ul {
    display: block;
    top: 30px;
    transform: translateX(-50%); }
    #app > ul > li {
      display: block;
      margin: 0 0 30px !important; } }
