@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

#speech-bubble {
  font-weight: 400;
  font-size: 20px;
  position: relative;
  width: 210px;
  background-color: #222222;
  border-radius: 22px;
  padding: 20px;
  color: #ffffff;
  text-align: left;
  display: block;
  margin-bottom: 20px;
  word-break: break-word; }
  #speech-bubble:before {
    bottom: -10px;
    left: 40px;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #222222; }
