@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

#social-container {
  position: relative; }

#social {
  display: flex;
  align-items: center;
  width: 90px;
  border-radius: 22px;
  background: #222222;
  padding: 16px 0 8px 2px;
  margin: 10px 0 0;
  position: relative; }
  #social ul {
    list-style: none;
    columns: 2;
    margin: 0 auto;
    padding: 0; }
    #social ul li {
      cursor: pointer;
      display: block;
      width: 24px;
      height: 30px;
      margin: 0 0 8px;
      padding: 4px 0; }
  #social i {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    display: block; }
    #social i.dribbble {
      background-image: url("../../../public/imgs/icons/dribbble.png"); }
    #social i.email {
      background-image: url("../../../public/imgs/icons/email.png"); }
    #social i.homepage {
      background-image: url("../../../public/imgs/icons/homepage.png"); }
    #social i.linkedin {
      background-image: url("../../../public/imgs/icons/linkedin.png"); }
    #social i.portfolio {
      background-image: url("../../../public/imgs/icons/portfolio.png"); }
    #social i.twitter {
      background-image: url("../../../public/imgs/icons/twitter.png"); }
    #social i.github {
      background-image: url("../../../public/imgs/icons/github.png"); }
    #social i.youtube {
      background-image: url("../../../public/imgs/icons/youtube.png"); }
    #social i.steam {
      background-image: url("../../../public/imgs/icons/steam.png"); }
    #social i.redbubble {
      background-image: url("../../../public/imgs/icons/redbubble.png"); }
    #social i.appstore {
      background-image: url("../../../public/imgs/icons/appstore.png"); }
  #social.highlight-dribbble {
    background-color: #ea4c89; }
    #social.highlight-dribbble i.dribbble {
      opacity: 1; }
    #social.highlight-dribbble i.email {
      opacity: 0.5; }
    #social.highlight-dribbble i.homepage {
      opacity: 0.5; }
    #social.highlight-dribbble i.linkedin {
      opacity: 0.5; }
    #social.highlight-dribbble i.portfolio {
      opacity: 0.5; }
    #social.highlight-dribbble i.twitter {
      opacity: 0.5; }
    #social.highlight-dribbble i.github {
      opacity: 0.5; }
    #social.highlight-dribbble i.youtube {
      opacity: 0.5; }
    #social.highlight-dribbble i.redbubble {
      opacity: 0.5; }
    #social.highlight-dribbble i.steam {
      opacity: 0.5; }
    #social.highlight-dribbble i.appstore {
      opacity: 0.5; }
  #social.highlight-email {
    background-color: #dd4b39; }
    #social.highlight-email i.dribbble {
      opacity: 0.5; }
    #social.highlight-email i.email {
      opacity: 1; }
    #social.highlight-email i.homepage {
      opacity: 0.5; }
    #social.highlight-email i.linkedin {
      opacity: 0.5; }
    #social.highlight-email i.portfolio {
      opacity: 0.5; }
    #social.highlight-email i.twitter {
      opacity: 0.5; }
    #social.highlight-email i.github {
      opacity: 0.5; }
    #social.highlight-email i.youtube {
      opacity: 0.5; }
    #social.highlight-email i.redbubble {
      opacity: 0.5; }
    #social.highlight-email i.steam {
      opacity: 0.5; }
    #social.highlight-email i.appstore {
      opacity: 0.5; }
  #social.highlight-homepage {
    background-color: #222222; }
    #social.highlight-homepage i.dribbble {
      opacity: 0.5; }
    #social.highlight-homepage i.email {
      opacity: 0.5; }
    #social.highlight-homepage i.homepage {
      opacity: 1; }
    #social.highlight-homepage i.linkedin {
      opacity: 0.5; }
    #social.highlight-homepage i.portfolio {
      opacity: 0.5; }
    #social.highlight-homepage i.twitter {
      opacity: 0.5; }
    #social.highlight-homepage i.github {
      opacity: 0.5; }
    #social.highlight-homepage i.youtube {
      opacity: 0.5; }
    #social.highlight-homepage i.redbubble {
      opacity: 0.5; }
    #social.highlight-homepage i.steam {
      opacity: 0.5; }
    #social.highlight-homepage i.appstore {
      opacity: 0.5; }
  #social.highlight-linkedin {
    background-color: #0077B5; }
    #social.highlight-linkedin i.dribbble {
      opacity: 0.5; }
    #social.highlight-linkedin i.email {
      opacity: 0.5; }
    #social.highlight-linkedin i.homepage {
      opacity: 0.5; }
    #social.highlight-linkedin i.linkedin {
      opacity: 1; }
    #social.highlight-linkedin i.portfolio {
      opacity: 0.5; }
    #social.highlight-linkedin i.twitter {
      opacity: 0.5; }
    #social.highlight-linkedin i.github {
      opacity: 0.5; }
    #social.highlight-linkedin i.youtube {
      opacity: 0.5; }
    #social.highlight-linkedin i.redbubble {
      opacity: 0.5; }
    #social.highlight-linkedin i.steam {
      opacity: 0.5; }
    #social.highlight-linkedin i.appstore {
      opacity: 0.5; }
  #social.highlight-portfolio {
    background-color: #222222; }
    #social.highlight-portfolio i.dribbble {
      opacity: 0.5; }
    #social.highlight-portfolio i.email {
      opacity: 0.5; }
    #social.highlight-portfolio i.homepage {
      opacity: 0.5; }
    #social.highlight-portfolio i.linkedin {
      opacity: 0.5; }
    #social.highlight-portfolio i.portfolio {
      opacity: 1; }
    #social.highlight-portfolio i.twitter {
      opacity: 0.5; }
    #social.highlight-portfolio i.github {
      opacity: 0.5; }
    #social.highlight-portfolio i.youtube {
      opacity: 0.5; }
    #social.highlight-portfolio i.redbubble {
      opacity: 0.5; }
    #social.highlight-portfolio i.steam {
      opacity: 0.5; }
    #social.highlight-portfolio i.appstore {
      opacity: 0.5; }
  #social.highlight-twitter {
    background-color: #55acee; }
    #social.highlight-twitter i.dribbble {
      opacity: 0.5; }
    #social.highlight-twitter i.email {
      opacity: 0.5; }
    #social.highlight-twitter i.homepage {
      opacity: 0.5; }
    #social.highlight-twitter i.linkedin {
      opacity: 0.5; }
    #social.highlight-twitter i.portfolio {
      opacity: 0.5; }
    #social.highlight-twitter i.twitter {
      opacity: 1; }
    #social.highlight-twitter i.github {
      opacity: 0.5; }
    #social.highlight-twitter i.youtube {
      opacity: 0.5; }
    #social.highlight-twitter i.redbubble {
      opacity: 0.5; }
    #social.highlight-twitter i.steam {
      opacity: 0.5; }
    #social.highlight-twitter i.appstore {
      opacity: 0.5; }
  #social.highlight-github {
    background-color: #2f2f2f; }
    #social.highlight-github i.dribbble {
      opacity: 0.5; }
    #social.highlight-github i.email {
      opacity: 0.5; }
    #social.highlight-github i.homepage {
      opacity: 0.5; }
    #social.highlight-github i.linkedin {
      opacity: 0.5; }
    #social.highlight-github i.portfolio {
      opacity: 0.5; }
    #social.highlight-github i.twitter {
      opacity: 0.5; }
    #social.highlight-github i.github {
      opacity: 1; }
    #social.highlight-github i.youtube {
      opacity: 0.5; }
    #social.highlight-github i.redbubble {
      opacity: 0.5; }
    #social.highlight-github i.steam {
      opacity: 0.5; }
    #social.highlight-github i.appstore {
      opacity: 0.5; }
  #social.highlight-youtube {
    background-color: #cd201f; }
    #social.highlight-youtube i.dribbble {
      opacity: 0.5; }
    #social.highlight-youtube i.email {
      opacity: 0.5; }
    #social.highlight-youtube i.homepage {
      opacity: 0.5; }
    #social.highlight-youtube i.linkedin {
      opacity: 0.5; }
    #social.highlight-youtube i.portfolio {
      opacity: 0.5; }
    #social.highlight-youtube i.twitter {
      opacity: 0.5; }
    #social.highlight-youtube i.github {
      opacity: 0.5; }
    #social.highlight-youtube i.youtube {
      opacity: 1; }
    #social.highlight-youtube i.redbubble {
      opacity: 0.5; }
    #social.highlight-youtube i.steam {
      opacity: 0.5; }
    #social.highlight-youtube i.appstore {
      opacity: 0.5; }
  #social.highlight-redbubble {
    background-color: #ec1c23; }
    #social.highlight-redbubble i.dribbble {
      opacity: 0.5; }
    #social.highlight-redbubble i.email {
      opacity: 0.5; }
    #social.highlight-redbubble i.homepage {
      opacity: 0.5; }
    #social.highlight-redbubble i.linkedin {
      opacity: 0.5; }
    #social.highlight-redbubble i.portfolio {
      opacity: 0.5; }
    #social.highlight-redbubble i.twitter {
      opacity: 0.5; }
    #social.highlight-redbubble i.github {
      opacity: 0.5; }
    #social.highlight-redbubble i.youtube {
      opacity: 0.5; }
    #social.highlight-redbubble i.redbubble {
      opacity: 1; }
    #social.highlight-redbubble i.steam {
      opacity: 0.5; }
    #social.highlight-redbubble i.appstore {
      opacity: 0.5; }
  #social.highlight-steam {
    background-color: #000000; }
    #social.highlight-steam i.dribbble {
      opacity: 0.5; }
    #social.highlight-steam i.email {
      opacity: 0.5; }
    #social.highlight-steam i.homepage {
      opacity: 0.5; }
    #social.highlight-steam i.linkedin {
      opacity: 0.5; }
    #social.highlight-steam i.portfolio {
      opacity: 0.5; }
    #social.highlight-steam i.twitter {
      opacity: 0.5; }
    #social.highlight-steam i.github {
      opacity: 0.5; }
    #social.highlight-steam i.youtube {
      opacity: 0.5; }
    #social.highlight-steam i.redbubble {
      opacity: 0.5; }
    #social.highlight-steam i.steam {
      opacity: 1; }
    #social.highlight-steam i.appstore {
      opacity: 0.5; }
  #social.highlight-appstore {
    background-color: #0A84FF; }
    #social.highlight-appstore i.dribbble {
      opacity: 0.5; }
    #social.highlight-appstore i.email {
      opacity: 0.5; }
    #social.highlight-appstore i.homepage {
      opacity: 0.5; }
    #social.highlight-appstore i.linkedin {
      opacity: 0.5; }
    #social.highlight-appstore i.portfolio {
      opacity: 0.5; }
    #social.highlight-appstore i.twitter {
      opacity: 0.5; }
    #social.highlight-appstore i.github {
      opacity: 0.5; }
    #social.highlight-appstore i.youtube {
      opacity: 0.5; }
    #social.highlight-appstore i.redbubble {
      opacity: 0.5; }
    #social.highlight-appstore i.steam {
      opacity: 0.5; }
    #social.highlight-appstore i.appstore {
      opacity: 1; }
  @media (max-width: 1099px) {
    #social {
      width: 160px;
      height: 90px;
      padding: 16px 24px 8px; }
      #social ul {
        columns: 3; }
        #social ul li {
          display: inline-block !important;
          width: 24px;
          height: 24px;
          margin: 0 0 8px;
          padding: 4px 0; } }
