@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

.experience h1 {
  font-weight: 700;
  font-size: 24px;
  width: 350px;
  text-align: right;
  white-space: normal;
  margin: 0;
  padding: 0;
  float: right; }

.experience h2 {
  font-weight: 500;
  font-style: italic;
  font-size: 20px;
  width: 350px;
  text-align: right;
  margin: 5px 0 15px;
  float: right; }

.experience span {
  font-family: 'Roboto', sans-serif; }

.experience .clear-both {
  clear: both; }

.experience .ongoing {
  font-weight: 800;
  width: 130px;
  height: 24px;
  display: block;
  background-color: #ffffff;
  color: #222222;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  padding: 2px 0;
  float: left;
  position: relative;
  margin-right: 12px; }
  .experience .ongoing:after {
    position: absolute;
    top: 1px;
    left: 128px;
    display: block;
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-left: 14px solid #ffffff;
    border-bottom: 11px solid transparent;
    content: ""; }

.experience .timespan {
  width: 350px;
  height: 30px;
  border-radius: 15px;
  padding: 4px 20px;
  float: right; }
  .experience .timespan .duration {
    float: left; }
    .experience .timespan .duration p {
      font-weight: 500;
      font-style: italic;
      font-size: 16px;
      text-align: left;
      text-transform: uppercase;
      display: inline-block; }
      .experience .timespan .duration p span {
        font-weight: 400;
        font-style: italic;
        margin: 0 5px 0 0; }
  .experience .timespan .period {
    float: right; }
    .experience .timespan .period .dash {
      width: 10px;
      height: 2px;
      border-radius: 1px;
      display: inline-block;
      background-color: #ffffff;
      margin: 5px; }
    .experience .timespan .period p {
      font-weight: 800;
      font-size: 16px;
      text-align: right;
      text-transform: uppercase;
      display: inline-block; }
      .experience .timespan .period p span {
        margin: 0 0 0 5px; }

@media (max-width: 1099px) {
  .experience .ongoing {
    display: none !important; }
  .experience h1, .experience h2, .experience .timespan {
    width: 100% !important; }
  .experience .timespan p {
    font-size: 14px !important; } }
