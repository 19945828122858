@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  #profile {
    margin: 0 0 136px;
    clear: both; } }

#profile h1 {
  clear: both; }
  #profile h1.name {
    font-weight: 700; }
  #profile h1.past {
    font-weight: 700;
    color: #aaaaaa;
    text-decoration: line-through; }
    #profile h1.past:hover {
      color: #222222;
      text-decoration: none; }
  #profile h1.nickname {
    font-weight: 700;
    color: #666666; }
    #profile h1.nickname:hover {
      color: #222222; }

#profile-avatar {
  position: relative;
  height: 330px; }
  @media (max-width: 1099px) {
    #profile-avatar {
      height: auto; } }
  #profile-avatar .show-on, #profile-avatar .show-off {
    position: absolute;
    bottom: 0; }
    @media (max-width: 1099px) {
      #profile-avatar .show-on, #profile-avatar .show-off {
        position: relative !important; } }
  #profile-avatar span {
    background-size: 100% 100%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    margin: 0 10px 10px 0;
    opacity: 1;
    transition: opacity 0.3s; }
    #profile-avatar span.default {
      background-image: url("../../../public/imgs/avatars/default.png");
      left: 0;
      bottom: 0; }
    #profile-avatar span.web-developer {
      background-image: url("../../../public/imgs/avatars/web-developer.png");
      float: left;
      clear: both; }
    #profile-avatar span.physicist {
      background-image: url("../../../public/imgs/avatars/physicist.png");
      float: left; }
    #profile-avatar span.ios-developer {
      background-image: url("../../../public/imgs/avatars/ios-developer.png");
      float: left;
      clear: both; }
    #profile-avatar span.tester {
      background-image: url("../../../public/imgs/avatars/tester.png");
      float: left; }
    #profile-avatar span.ui-designer {
      background-image: url("../../../public/imgs/avatars/ui-designer.png");
      float: left;
      clear: both; }
    #profile-avatar span.backend-developer {
      background-image: url("../../../public/imgs/avatars/backend-developer.png");
      float: left; }
  @media (min-width: 1100px) {
    #profile-avatar.on .show-on {
      display: block; }
    #profile-avatar.on .show-off {
      display: none; }
    #profile-avatar.off .show-on {
      display: none; }
    #profile-avatar.off .show-off {
      display: block; } }
