@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

#occupations {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  z-index: 10;
  color: #ffffff;
  padding: 0 40px;
  margin: 0;
  overflow-y: auto;
  transition: width 0.3s; }
  #occupations.physicist {
    background-color: #FFD166; }
  #occupations.tester {
    background-color: #8948C7; }
  #occupations.backend-developer {
    background-color: #55ACEE; }
  #occupations.web-developer {
    background-color: #E42955; }
  #occupations.ios-developer {
    background-color: #06D6A0; }
  #occupations.ui-designer {
    background-color: #FC6DAB; }
  #occupations.scrum-master {
    background-color: #FF8047; }
  #occupations.game-developer {
    background-color: #222222; }
  #occupations.physicist .timespan {
    background-color: #ffb200; }
  #occupations.tester .timespan {
    background-color: #562881; }
  #occupations.backend-developer .timespan {
    background-color: #147bc9; }
  #occupations.web-developer .timespan {
    background-color: #941331; }
  #occupations.ios-developer .timespan {
    background-color: #037356; }
  #occupations.ui-designer .timespan {
    background-color: #fa0971; }
  #occupations.scrum-master .timespan {
    background-color: #e04500; }
  #occupations.game-developer .timespan {
    background-color: black; }
  #occupations > div {
    float: right; }
    #occupations > div > div {
      margin: 40px 0 0; }
      #occupations > div > div:first-child {
        margin: 0; }
      #occupations > div > div > h1 {
        font-weight: 700;
        font-style: italic;
        text-align: right; }
  #occupations ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    #occupations ul li {
      margin: 40px 0 0; }
  #occupations.undefined {
    background-color: #222222;
    padding: 0 !important;
    width: 0 !important; }
  #occupations i.close {
    background-image: url("../../../public/imgs/icons/close.png");
    background-size: 100%;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    opacity: 1; }
  @media (max-width: 1099px) {
    #occupations {
      width: 100% !important;
      padding: 70px 20px !important; }
      #occupations > div > div > h1 {
        font-size: 36px !important;
        white-space: normal; } }
