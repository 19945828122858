@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

body, html, #root {
  font-family: 'Raleway', sans-serif;
  color: #222222;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; }
  @media (min-width: 1100px) {
    body, html, #root {
      overflow: hidden; } }

h1 {
  font-weight: 500;
  font-size: 36px;
  margin: 0;
  padding: 3px 0;
  text-align: left;
  white-space: nowrap;
  cursor: default; }
  @media (max-width: 1099px) {
    h1 {
      font-size: 24px !important; } }

.info {
  font-weight: 400;
  font-style: italic;
  background-color: #222222;
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  display: block;
  padding: 5px 15px;
  border-radius: 20px;
  cursor: default;
  white-space: nowrap; }
