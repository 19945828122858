@media (max-width: 1099px) {
  .show-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  .hide-desktop {
    display: none !important; } }

@media (min-width: 1100px) {
  #menu {
    margin: 0 0 0px; } }

#menu h1 {
  cursor: default; }
  #menu h1.current {
    font-weight: 700; }
  #menu h1.interest {
    font-weight: 700;
    color: #666666; }
  #menu h1.past {
    font-weight: 700;
    color: #aaaaaa;
    text-decoration: line-through; }
  #menu h1.hoverable:hover {
    color: #222222;
    text-decoration: none; }
    #menu h1.hoverable:hover.physicist {
      color: #FFD166; }
    #menu h1.hoverable:hover.tester {
      color: #8948C7; }
    #menu h1.hoverable:hover.backend-developer {
      color: #55ACEE; }
    #menu h1.hoverable:hover.web-developer {
      color: #E42955; }
    #menu h1.hoverable:hover.ios-developer {
      color: #06D6A0; }
    #menu h1.hoverable:hover.ui-designer {
      color: #FC6DAB; }
    #menu h1.hoverable:hover.scrum-master {
      color: #FF8047; }
    #menu h1.hoverable:hover.game-developer {
      color: #222222; }
  #menu h1.clickable {
    cursor: pointer; }

#menu .menu-item-container {
  position: relative; }
